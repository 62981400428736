import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);

/* Layout */
import Layout from '@/layout';

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export var constantRoutes = [{
  path: '/login',
  component: function component() {
    return import('@/views/login/index');
  },
  hidden: true
}, {
  path: '/demo',
  component: function component() {
    return import('@/views/map/map-draw.vue');
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return import('@/views/404');
  },
  hidden: true
}, {
  path: '/',
  component: Layout,
  redirect: '/home',
  children: [{
    path: 'Home',
    name: 'Home',
    component: function component() {
      return import('@/views/home/index');
    },
    meta: {
      title: 'Home',
      icon: 'menu',
      affix: true
    }
  }]
}, {
  path: '/monitor/job-log',
  component: Layout,
  hidden: true,
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/job/jobLog.vue');
    },
    name: '调度日志',
    meta: {
      title: '调度日志',
      activeMenu: '/monitor/jobLog'
    }
  }]
},
// {
//   path: '/',
//   component: Layout,
//   redirect: '/dashboard',
//   children: [{
//     path: 'dashboard',
//     name: 'Dashboard',
//     component: () => import('@/views/dashboard/index'),
//     meta: { title: 'dashboard', icon: 'dashboard', affix: true }
//   }]
// },

{
  path: '/account',
  component: Layout,
  hidden: true,
  redirect: 'noredirect',
  children: [{
    path: 'profile',
    name: '个人资料',
    component: function component() {
      return import('@/views/account/profile.vue');
    },
    meta: {
      title: '个人资料'
    }
  }, {
    path: 'timeline',
    name: '最近活动',
    component: function component() {
      return import('@/views/account/timeline.vue');
    },
    hidden: true,
    meta: {
      title: '最近活动'
    }
  }, {
    path: 'updatePwd',
    name: '修改密码',
    component: function component() {
      return import('@/views/account/updatePwd.vue');
    },
    hidden: true,
    meta: {
      title: '修改密码'
    }
  }, {
    path: '/map-draw',
    component: function component() {
      return import('@/views/map/map-draw.vue');
    },
    hidden: true
  }]
}];
var createRouter = function createRouter() {
  return new Router({
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();
export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher;
}
export default router;